/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
// import { Link } from 'components/link';
import { Link as GatsbyLink } from 'gatsby';

export default function Logo({ src, ...rest }) {
  return (
    <GatsbyLink
      to="/"
      sx={{
        variant: 'links.logo',
        display: 'flex',
        cursor: 'pointer',
        mr: 15,
      }}
      {...rest}
    >
      <Image src={src} loading="lazy" alt="Green World" />
    </GatsbyLink>
  );
}

