/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SearchEngineOptimization({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keyword
            url
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Fragment>
      <Helmet 
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      description={metaDescription} >
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
      <meta name="name" content="metaDescription" />
      <meta name="keywords" content={site.siteMetadata.keyword}/>
      <meta name="og:title" content="title || site.siteMetadata.title" />
      <meta name="og:description" content={site.siteMetadata.description} />
      <link rel="canonical" href={site.siteMetadata.url} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content="title" />
      <meta name="twitter:description" content="metaDescription" />
      </Helmet>
    </Fragment>
  );
}

SearchEngineOptimization.defaultProps = {
  lang: `en`,
  meta: [],
  title:``,
  description: ``,
};

export default SearchEngineOptimization;
