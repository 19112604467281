/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Box, Heading, Button } from 'theme-ui';

import BgShape from 'assets/shape-1.svg';
import btnShapeTop from 'assets/btn-shape-top.svg';
import btnShapeBottom from 'assets/btn-shape-bottom.svg';

export default function CallToAction() {
  const data = useStaticQuery(graphql`
  {
    contentfulActionCallSection {
      heading
      actionButton {
        name
        phoneNumber
      }
    }
  }
`)
  const title = data.contentfulActionCallSection.heading;
  const button = data.contentfulActionCallSection.actionButton;
  
  return (
    <Box as="section" sx={styles.section}>
      <div sx={styles.wrapper} id="contact">
        <Container sx={styles.container}>
          <Box sx={styles.contentBox}>
            <Heading sx={styles.heading}>
            {title}
            </Heading>
            <Box sx={styles.btnWrapper}>
              <Button as="a" href={button.phoneNumber} variant="whiteButton" aria-label="Contact Us">
                {button.name}
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </Box>
  );
}

const styles = {
  section:{
    paddingTop:'40px'
  },
  wrapper: {
    position: 'relative',
    zIndex: '10',
    top: -100,
    mb: -9,
  },
  container: {
    px: [0, '0 !important', '30px !important'],
  },
  contentBox: {
    padding: ['55px 30px 60px', null, null, '55px 30px 60px', '55px 50px 60px'],
    backgroundColor: 'primary',
    backgroundImage: ['none', null, null, `url(${BgShape})`],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '60% center',
    backgroundSize: ['120px', null, null, null, 'auto'],
    borderRadius: '7px',
    '@media screen and (max-width: 320px)': {
      borderRadius:'0'     
     },
    display: 'flex',
    alignItems: 'center',
    textAlign: ['center', null, null, 'left'],
    flexDirection: ['column', null, null, 'row'],
    justifyContent: ['center', null, null, 'space-between'],
  },
  heading: {
    fontSize: [6, 7, 8, null, 9],
    fontFamily: 'heading',
    color: 'white',
    letterSpacing: 'normal',
    fontWeight: 'body',
    lineHeight: [1.2],
    width: ['100%', '80%', null, 400, '50%', '40%'],
    mb: [5, null, null, 0],
    mt: -1,
  },
  btnWrapper: {
    display: 'flex',
    position: 'relative',
    ':before, :after': {
      content: '""',
      position: 'absolute',
      width: '73px',
      height: '26px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      left: '50%',
      transform: 'translateX(-50%)',
      display: ['none', null, null, 'block'],
    },
    ':before': {
      backgroundImage: `url(${btnShapeTop})`,
      top: '-38px',
    },
    ':after': {
      backgroundImage: `url(${btnShapeBottom})`,
      bottom: '-38px',
    },
  },
};
