import React, { useContext } from 'react';
import { Box, Image } from 'theme-ui';
import { Link } from 'react-scroll';
import Drawer from 'components/drawer';
import { useStaticQuery, graphql } from 'gatsby';
import { Scrollbars } from 'react-custom-scrollbars';
import { DrawerContext } from '../../contexts/drawer/drawer.context';

import close from 'assets/icons/close.png'
import navigationMenu from 'assets/icons/menu-rounded.png'

const MobileDrawer = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulHeader {
      navigationElement {
        name
        slug
      }
    },
    contentfulFooter {
      footerSocials {
        name
        socialMediaLinks {
          name
          url
          icon {
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
`)

  const navItems = data.contentfulHeader.navigationElement;
  const social = data.contentfulFooter.footerSocials.socialMediaLinks;

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = React.useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);

  return (
    <Drawer
      width="320px"
      drawerHandler={
        <Box sx={styles.handler}>
          <Image src={navigationMenu} loading="lazy" alt="navigation menu" className="menuIconSize" />
        </Box>
      }
      open={state.isOpen}
      toggleHandler={toggleHandler}
      closeButton={<Image src={close} loading="lazy" alt="close-icon" className="closeIconSize" />}
      drawerStyle={styles.drawer}
      closeBtnStyle={styles.close}
    >
      <Scrollbars autoHide>
        <Box sx={styles.content}>
          <Box sx={styles.menu}>
          {navItems.map(({ slug, name }, i) => (
              <Link
                activeClass="active"
                to={slug}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
              >
                {name}
              </Link>
            ))}
          </Box>
          <Box sx={styles.menuFooter}>
            <Box sx={styles.social}>
            {social.map(({ name, url, icon }, i) => (
              <Box as="span" key={i} sx={styles.social.icon}>
                <a href={url} target="_blank" rel="noreferrer" className={name}>
                  <Image src={icon.file.url} loading="lazy" alt={icon.file.fileName}/>
                </a>
              </Box>
            ))}
            </Box>
          </Box>
        </Box>
      </Scrollbars>
    </Drawer>
  );
};

const styles = {
  handler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    width: '26px',
    cursor: 'pointer',
    '@media screen and (min-width: 1024px)': {
      display: 'none',
    },
    '.menuIconSize':{
      width:'26px',
  },
  },
  '.closeIconSize':{
    width:'24px',
    color:'#000000'
  },
  drawer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'dark',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '25px',
    right: '30px',
    zIndex: '1',
    cursor: 'pointer',
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    pt: '100px',
    pb: '40px',
    px: '30px',
  },

  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    a: {
      fontSize: '16px',
      fontWeight: '500',
      color: 'text_white',
      py: '15px',
      cursor: 'pointer',
      borderBottom: '1px solid #e8e5e5',
      transition: 'all 0.25s',
      '&:hover': {
        color: 'primary',
      },
      '&.active': {
        color: 'primary',
      },
    },
  },

  menuFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
    paddingTop:'45px'
  },

  social: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      fontSize: 1,
      mr: 4,
      transition: 'all 0.25s',
      cursor: 'pointer',
      '.facebook':{
        color:'#4267B2'
      },
      '.twitter':{
        color:'#1DA1F2'
      },
      '.instagram':{
        color:'#405DE6'
      },
      svg: {
        fill: 'text',
        transition: 'all 0.25s',
      },
      ':last-child': {
        mr: '0',
      },
      '&:hover svg': {
        fill: 'primary',
      },
    },
  },

  button: {
    color: 'white',
    fontSize: '14px',
    fw: '700',
    height: '45px',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    py: '0',
  },
};

export default MobileDrawer;
