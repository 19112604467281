/** @jsx jsx */
import { jsx } from 'theme-ui'
import Logo from 'components/logo';
import { Link } from 'react-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import CallToAction from 'sections/call-to-action';
import { Heading, Box, Flex, Container, Image } from 'theme-ui';
import heart from 'assets/heart.svg'

export default function Footer() {
  const data = useStaticQuery(graphql`
  {
    contentfulFooter {
      id
      logo {
        id
        logoImage {
          id
          file {
            fileName
            url
          }
        }
      }
      footerSocials {
        id
        name
        socialMediaLinks {
          id
          name
          url
          icon {
            id
            file {
              url
              fileName
            }
          }
        }
      }
      footerMenus {
        id
        title
        footerMenuNavigation {
          id
          slug
          title
          name
        }
      }
    }
  }
`)
  const menu = data.contentfulFooter.footerMenus;
  const logo = data.contentfulFooter.logo.logoImage.file;
  const social = data.contentfulFooter.footerSocials.socialMediaLinks;
                    
  return (
    <Box as="section" sx={styles.section}>
    <footer sx={styles.footer}>
    <CallToAction />
      <Container sx={styles.footer.container}>
        <Box sx={styles.footer.footerTopArea}>
          <Box sx={styles.copyrightArea}>
            <Box className="footer__logo">
            <Logo src={logo.url} alt={logo.fileName} className="footerLogo" />
            </Box>
          </Box>
          <Flex sx={styles.footer.menuArea}>   
          {menu && menu.map(({ id, title, footerMenuNavigation }) => (
            <Box sx={styles.footer.menus}>
              <Heading key={id} sx={styles.footer.heading}>{title}</Heading>
              {footerMenuNavigation.map((link, i) => {
                return (<nav className="navStyle" key={i} >
                  <Link
                  key={i}
                  to={link.slug}
                  spy={true}
                  offset={-70}
                  smooth={true}
                  duration={500}
                  className={link.name}
                  sx={styles.footer.link}>
                  {link.title}
                </Link>
                </nav>)
                })}
            </Box>
          ))}
            <Box>
              <Flex sx={styles.social}>
                <Heading sx={styles.footer.heading}>STAY TUNED</Heading>
                {social.map(({ name, url, icon }, i) => (
                  <Box as="span" className="socialList" key={i}>
                    <a href={url} target="_blank" rel="noreferrer" className={name} sx={styles.footer.link}>
                      <Image src={icon.file.url} loading="lazy" alt={icon.file.fileName}/>
                    </a>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Box>
        <p sx={styles.copyrightArea.copyright}>
           Copyright {new Date().getFullYear()}, All Rights Reserved | Crafted with <Image src={heart} className="copySign" alt="heart-sign" sx={styles.heartIcon}/><a href="https://assutech.gm/" rel="noreferrer" className="author" target="_blank">Assutech</a>
        </p>
      </Container>
    </footer>
    </Box>
  );
}
const styles = {
  section:{
    paddingTop: [8, null, 9, null, null, 10],
  },
  heartIcon:{
  lineHeight: '1.1',
  margin: 'auto 4px',
  width:'14px',
  },
  social:{
    display:'block',
    '.socialList':{
      display:'inline-flex',
      alignItems:'baseline'
    },
    '.facebook':{
      width:'22px',
      color:'muted',
      ':hover': {
        color: '#1877F2',
      },
    },
    '.twitter':{
      width:'22px',
      color:'muted',
      ':hover': {
        color: '#1DA1F2',
      },
    },
    '.instagram':{
      width:'22px',
      color:'muted',
      ':hover': {
        color: '#c32aa3',
      },
    },
    '.linkedin':{
      width:'24px',
      color:'muted',
      ':hover': {
        color: '#0A66C2',
      },
    },
  },
  footer: {
    fontFamily:'heading',
    backgroundColor:'secondary',
    container: {
      width: '100%',
      alignItems: 'stretch',
    },
    p:{
        display:'flex',
        justifyContent:'center',
        margin:'auto'
    },
    '.navStyle':{
      display:'block',
      alignItems:'center'
    },
    h4:{
      fontSize:'14px',
      marginRight:'8px'
    },
    footerTopArea: {
      display: ['grid', 'flex'],
      flexWrap: 'wrap',
      pt: ['60px', null, null, null, 8],
      pb: [7, null, null, null, '0'],
      pl: [0, null, 4, 6, null, 7],
      pr: [0, null, 4, 6],
    },
    menuArea: {
      width: [
        '100%',
        null,
        null,
        null,
        'calc(100% - 250px)',
        'calc(100% - 300px)',
      ],
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      pb: 3,
    },
    menus: {
      display: 'flex',
      flexDirection: 'column',
      mb: ['45px', null, null, '50px', '60px'],
      pr: 3,
      width: ['100%', null, null, '30%'],
    },
    heading: {
      fontSize: [2, 3],
      color: 'heading',
      fontWeight: 'heading',
      litterSpacing: 'heading',
      mb: [4, null, null, null, 5, 3],
      lineHeight: '1.35',
    },

    link: {
      fontSize: ['14px', 1],
      color: 'text',
      fontWeight: 'body',
      mb: 2,
      marginRight:'12px',
      cursor: 'pointer',
      transition: 'all 0.35s',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      lineHeight: [1.5, null, 1.9],
      svg: {
        width: '17px',
        mr: 2,
        fontSize: 2,
        flexShrink: 0,
      },
      ':hover': {
        color: 'primary',
      },
      ':last-child': {
        mb: '0px',
      },
    },
  },
  copyrightArea: {
    width: ['100%', null, null, null, '250px', '300px'],
    flexShrink: 0,
    order: [2, null, null, null, 0],
    textAlign: ['center', null, null, null, 'left'],
    '.copySign':{
      width:'14px'
    },
    '.footerLogo':{
      width:'200px',
      '@media screen and (max-width: 320px)': {
        width:'120px',     
       },
    },
    '.footer__logo': {
      a: {
        mr: 0,
        img: {
          mx: ['auto', null, null, null, 0],
        },
      },
    },
    '.footer__menu': {
      display: 'flex',
      justifyContent: ['center', null, null, null, 'flex-start'],
      flexWrap: 'wrap',
      mt: [3, null, null, null, 4],
      a: {
        fontSize: ['14px', 1],
        color: 'text_secondary',
        fontWeight: 'body',
        mb: 1,
        cursor: 'pointer',
        transition: 'all 0.35s',
        textDecoration: 'none',
        lineHeight: [1.5, null, 1.9],
        ':before': {
          px: 2,
          content: '"|"',
          color: 'text_secondary',
        },
        ':first-of-type:before': {
          display: 'none',
        },
        ':hover': {
          color: 'primary',
        },
      },
    },
    copyright: {
      fontSize: '14px',
      color: 'muted',
      padding: '35px 0',
      fontFamily:'heading',
      '@media screen and (max-width: 320px)': {
        fontSize:'12px',
        display:'contents',     
       },
       '.author':{
        textDecoration:'none',
        color:'muted'
      },
    },
  },
};